<template>
  <div>
    <div>
      <div v-if="activeGallery" class="columns is-vcentered">
        <div class="column">
          <p class="title">Active Gallery</p>
          <div class="columns is-align-items-end">
            <div class="column is-4">
              <b-field label="Title">
                <b-input v-model="galleryTitle" />
              </b-field>
            </div>
            <div class="column">
              <b-field label="Subtitle">
                <b-input v-model="gallerySubtitle" />
              </b-field>
            </div>
            <div class="column is-narrow">
              <b-button type="is-info" @click="updateHeading"
                >Update Headings</b-button
              >
            </div>
          </div>
        </div>
      </div>
      <div v-else class="columns is-vcentered">
        <div class="column">
          <p class="title">
            <i class="mdi mdi-information-outline mr-2"></i>
            Loading Active Gallery
          </p>
        </div>
      </div>
      <div v-if="activeGallery" class="columns mt-2" style="overflow-x: scroll">
        <div
          v-for="(image, index) in activeGallery.images"
          :key="index"
          class="column is-narrow has-text-centered px-5 pb-5"
        >
          <div class="box">
            <div class="py-5">
              <img :src="image.attachmentURL" style="height: 150px" />
              <p class="text">{{ image.title.slice(0, 25) }}</p>
            </div>
            <div class="columns">
              <div class="column"></div>
              <div class="column is-narrow">
                <b-button
                  type="is-danger"
                  icon-left="close"
                  @click="removeImage(image._id)"
                  >Remove</b-button
                >
              </div>
              <div class="column"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="columns is-vcentered mt-5">
      <div class="column">
        <p class="title">Add to Gallery</p>
      </div>
    </div>
    <div class="columns is-vcentered">
      <div class="column is-3">
        <b-field>
          <b-input
            placeholder="Search..."
            type="search"
            v-model="searchQuery"
            @input="filterItems"
          ></b-input>
        </b-field>
      </div>
      <div class="column">
        <b-field>
          <div class="columns is-vcentered">
            <div class="column is-narrow bold">Search by:</div>
            <div class="column">
              <b-radio
                class="mt-1 pl-2"
                v-for="(columnData, index) in tableProps.columnData"
                :key="index"
                v-model="searchType"
                name="searchType"
                :native-value="columnData"
                @input="filterItems"
              >
                {{ tableProps.columnTitle[index] }}
              </b-radio>
            </div>
          </div>
        </b-field>
      </div>
    </div>
    <b-table
      :data="filteredItems"
      :loading="tableProps.isLoading"
      :striped="tableProps.isStriped"
      :total="tableProps.total"
      :per-page="tableProps.perPage"
      aria-next-label="Next page"
      aria-previous-label="Previous page"
      aria-page-label="Page"
      aria-current-label="Current page"
      paginated
    >
      <b-table-column
        v-for="(columnData, index) in tableProps.columnData"
        :key="index"
        :field="columnData"
        :label="tableProps.columnTitle[index]"
        sortable
        v-slot="props"
      >
        {{
          Array.isArray(props.row[columnData])
            ? props.row[columnData].join(", ")
            : props.row[columnData] | filter
        }}
      </b-table-column>

      <b-table-column field="actions" label="Actions" sortable v-slot="props">
        <span
          class="has-text-success pointer-active"
          @click="openViewDetailModal(props.row)"
        >
          <i class="mdi mdi-eye pr-1"></i> View
        </span>
        <span
          class="has-text-info pointer-active ml-4"
          @click="addImage(props.row._id)"
        >
          <i class="mdi mdi-plus pr-1"></i> Add to Gallery
        </span>
      </b-table-column>
    </b-table>
    <!-- Modals Start -->
    <b-modal v-model="modals.showViewDetailModal" has-modal-card>
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">Digital Media Details</p>
        </header>
        <section v-if="itemDetail" class="modal-card-body">
          <div class="columns">
            <div class="column">
              <b-field
                v-for="(item, index) in modals.viewModalTitles"
                :key="index"
                :label="
                  $options.filters.formatCamelCase(item) == 'Authors'
                    ? 'Contributor Names'
                    : $options.filters.formatCamelCase(item)
                "
              >
                {{
                  Array.isArray(itemDetail[item])
                    ? itemDetail[item].join(", ")
                    : itemDetail[item] | filter
                }}
              </b-field>
            </div>
            <div class="column is-6 has-text-right mr-4">
              <div v-if="contentType == 'image'">
                <img :src="itemDetail.attachmentURL" />
              </div>
              <div v-else-if="contentType == 'video'">
                <a :href="itemDetail.attachmentURL" target="_blank">
                  <b-button type="is-danger" icon-left="play">
                    Play Video
                  </b-button>
                </a>
              </div>
              <div
                v-else-if="
                  (contentType != 'image' || contentType != 'video') &&
                  itemDetail.attachmentURL
                "
              >
                <a :href="itemDetail.attachmentURL">
                  <b-button type="is-info" icon-left="download">
                    Download Resource
                  </b-button>
                </a>
              </div>
              <div v-else class="my-5">
                <i class="mdi mdi-information-outline pr-1"></i> No File
                uploaded
              </div>
            </div>
          </div>
        </section>
        <footer class="modal-card-foot">
          <b-button label="Close" @click="closeViewDetailModal" />
        </footer>
      </div>
    </b-modal>
    <b-modal v-model="modals.showDeleteItemModal" has-modal-card>
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">Delete Digital Media</p>
        </header>
        <section v-if="itemDetail" class="modal-card-body">
          <b-message type="is-danger">
            <div class="columns is-vcentered">
              <div class="column">
                Are you sure you want to delete this content?
              </div>
              <div class="column is-narrow">
                <b-button label="Cancel" @click="closeDeleteItemModal" />
                <b-button
                  label="Confirm"
                  @click="deleteItem(itemDetail._id)"
                  type="is-danger"
                  class="ml-2"
                />
              </div>
            </div>
          </b-message>
          <div class="columns">
            <div class="column">
              <b-field
                v-for="(item, index) in modals.viewModalTitles"
                :key="index"
                :label="$options.filters.formatCamelCase(item)"
              >
                {{
                  Array.isArray(itemDetail[item])
                    ? itemDetail[item].join(", ")
                    : itemDetail[item] | filter
                }}
              </b-field>
            </div>
            <div class="column is-6 has-text-right mr-4">
              <div v-if="contentType == 'image'">
                <img :src="itemDetail.attachmentURL" />
              </div>
              <div v-else-if="contentType == 'video'">
                <a :href="itemDetail.attachmentURL" target="_blank">
                  <b-button type="is-danger" icon-left="play">
                    Play Video
                  </b-button>
                </a>
              </div>
              <div
                v-else-if="
                  (contentType != 'image' || contentType != 'video') &&
                  itemDetail.attachmentURL
                "
              >
                <a :href="itemDetail.attachmentURL">
                  <b-button type="is-info" icon-left="download">
                    Download Resource
                  </b-button>
                </a>
              </div>
              <div v-else class="my-5">
                <i class="mdi mdi-information-outline pr-1"></i> No File
                uploaded
              </div>
            </div>
          </div>
        </section>
        <footer class="modal-card-foot"></footer>
      </div>
    </b-modal>
    <!-- Modals End -->
  </div>
</template>

<script>
import { orderBy, filter } from "lodash";
import api from "../../../services/dataService";
import notificationService from "../../../services/notificationService";

export default {
  name: "Viewer",
  data() {
    return {
      contentType: "image",
      contentTypes: ["image", "video", "audio", "map", "dataset"],
      filteredItems: undefined,
      searchQuery: undefined,
      searchType: "title",
      tableProps: {
        columnTitle: ["Digital Media ID.", "Title", "Type"],
        columnData: ["_id", "title", "type"],
        total: 0,
        isLoading: false,
        isStriped: true,
        sortOrder: "desc",
        defaultSortOrder: "desc",
        page: 1,
        perPage: 15
      },
      modals: {
        showViewDetailModal: false,
        showDeleteItemModal: false,
        viewModalTitles: [
          "Digital Media ID",
          "Title",
          "Type",
          "Attachment URL",
          "Description",
          "Contributor Names",
          "Published Date",
          "Subject",
          "Keywords",
          "Genre",
          "Notes",
          "Medium",
          "Call Number",
          "Source",
          "Rights Advisory",
          "Access Advisory",
          "Online Format",
          "Date Added"
        ],
        viewModalTitlesData: [
          "_id",
          "title",
          "type",
          "attachmentURL",
          "description",
          "contributorNames",
          "publishedDate",
          "subject",
          "keywords",
          "genre",
          "notes",
          "medium",
          "callNumber",
          "source",
          "rightsAdvisory",
          "accessAdvisory",
          "onlineFormat",
          "dateCreated"
        ]
      },
      itemDetail: undefined,
      activeGallery: undefined,
      galleryTitle: "",
      gallerySubtitle: ""
    };
  },
  async created() {
    if (this.$route.query.contentType) {
      this.contentType = this.$route.query.contentType.toString();
    }
    this.tableProps.isLoading = true;
    await this.$store.dispatch("getDigitalMedia");
    this.filteredItems = this.items;
    this.tableProps.isLoading = false;
    this.filterItems();
    this.getGallery();
  },
  computed: {
    items() {
      return orderBy(this.$store.state.digitalMedia, ["dateCreated"], ["desc"]);
    }
  },
  watch: {
    items: function (val) {
      this.filteredItems = val;
    }
  },
  methods: {
    getGallery() {
      api
        .get("/gallery")
        .then((res) => {
          this.activeGallery = res.data;
          this.galleryTitle = res.data.title;
          this.gallerySubtitle = res.data.subtitle;
        })
        .catch((err) => notificationService.error(err.message[0]));
    },
    async removeImage(id) {
      api
        .delete("/gallery/" + id)
        .then(async () => {
          notificationService.success("Image removed from gallery");
          await this.getGallery();
        })
        .catch((err) => notificationService.error(err.message[0]));
    },
    async addImage(id) {
      api
        .post("/gallery/" + id)
        .then(async () => {
          notificationService.success("Image added to gallery");
          await this.getGallery();
        })
        .catch((err) => notificationService.error(err.message[0]));
    },
    async updateHeading() {
      api
        .post("/gallery/heading", {
          title: this.galleryTitle,
          subtitle: this.gallerySubtitle
        })
        .then(async () => {
          notificationService.success("Headings changed succesfully");
          await this.getGallery();
        })
        .catch((err) => notificationService.error(err.message[0]));
    },
    setFields(type) {
      let fields = [];
      if (type == "image") {
        fields = [
          "title",
          "description",
          "authors",
          "subject",
          "keywords",
          "notes",
          "publicationYear",
          "medium",
          "callNo"
        ];
      } else if (type == "video") {
        fields = [
          "title",
          "description",
          "authors",
          "subject",
          "keywords",
          "weblink",
          "notes",
          "publicationYear",
          "duration"
        ];
      } else if (type == "audio") {
        fields = [
          "title",
          "description",
          "authors",
          "subject",
          "keywords",
          "notes",
          "publicationYear",
          "duration"
        ];
      } else if (type == "map") {
        fields = [
          "title",
          "description",
          "authors",
          "subject",
          "keywords",
          "publisher",
          "placeOfPublication",
          "notes",
          "publicationYear"
        ];
      } else if (type == "dataset") {
        fields = [
          "title",
          "description",
          "authors",
          "subject",
          "keywords",
          "publisher",
          "dataType",
          "dataSource",
          "category",
          "license",
          "format",
          "placeOfPublication",
          "notes",
          "publicationYear"
        ];
      }
      this.modals.viewModalTitles = fields;
    },
    openViewDetailModal(selectedItem) {
      this.setFields(selectedItem.type);
      this.itemDetail = selectedItem;
      this.modals.showViewDetailModal = true;
    },
    closeViewDetailModal() {
      this.modals.showViewDetailModal = false;
    },
    openDeleteItemModal(selectedItem) {
      this.itemDetail = selectedItem;
      this.modals.showDeleteItemModal = true;
    },
    closeDeleteItemModal() {
      this.modals.showDeleteItemModal = false;
    },
    async deleteItem(itemId) {
      await this.$store.dispatch("deleteDigitalMedia", itemId);
      this.closeDeleteItemModal();
    },
    addItem() {
      this.$router.push({ name: "DigitalMediaEditor" });
    },
    editItem(item) {
      this.$router.push({
        name: "DigitalMediaEditor",
        params: { itemDetail: item }
      });
    },
    filterItems() {
      if (this.items) {
        if (this.searchQuery === "") {
          this.filteredItems = filter(this.items, { type: this.contentType });
        } else if (this.searchQuery) {
          this.filteredItems = filter(this.items, { type: this.contentType });
          this.filteredItems = filter(this.filteredItems, (item) => {
            return item[this.searchType]
              .toString()
              .toLowerCase()
              .includes(this.searchQuery.toLowerCase());
          });
        } else {
          this.filteredItems = filter(this.items, { type: this.contentType });
        }
      }
    }
  }
};
</script>

<style lang="scss">
@import "@/assets/styles/main.scss";
</style>
