<template>
  <div>
    <div class="columns is-vcentered">
      <div class="column">
        <p class="title">Customize Notice</p>
      </div>
    </div>
    <hr />
    <div v-if="!isLoading" class="columns">
      <div class="column">
        <form @submit.prevent="submitForm" id="editorForm">
          <div class="box">
            <div class="columns">
              <div class="column">
                <div>
                  <b-message
                    :type="
                      formData.enabled == true ? 'is-success' : 'is-danger'
                    "
                  >
                    <div class="columns">
                      <div class="column">
                        <i
                          v-if="formData.enabled == true"
                          class="mdi mdi-check pr-2"
                        ></i>
                        <i v-else class="mdi mdi-close pr-2"></i>
                        Notice
                        {{ formData.enabled == true ? "Enabled" : "Disabled" }}
                      </div>
                      <div class="column is-narrow">
                        <b-switch
                          v-model="formData.enabled"
                          :true-value="true"
                          :false-value="false"
                          type="is-success"
                        >
                        </b-switch>
                      </div>
                    </div>
                  </b-message>
                  <b-field label="Message*" horizontal>
                    <b-input
                      type="textarea"
                      v-model="formData.message"
                      required
                    ></b-input>
                  </b-field>

                  <b-field label="Icon*" horizontal>
                    <b-input v-model="formData.icon" required> </b-input>
                    <p class="mt-2">
                      For icon codes,
                      <a
                        href="https://materialdesignicons.com/"
                        target="_blank"
                        class=""
                        >click here.</a
                      >
                    </p>
                  </b-field>

                  <b-field label="Background Colour*" horizontal>
                    <chrome-picker v-model="formData.backgroundColour">
                    </chrome-picker>
                  </b-field>

                  <b-field label="Foreground Colour*" class="mt-5" horizontal>
                    <div>
                      <b-radio
                        v-model="formData.foregroundColour"
                        name="foregroundColour"
                        native-value="#1c1c1c"
                        class="mt-2"
                        type="is-info"
                      >
                        Black
                      </b-radio>
                      <b-radio
                        v-model="formData.foregroundColour"
                        name="foregroundColour"
                        native-value="#f1f1f1"
                        class="mt-2 ml-2"
                        type="is-info"
                      >
                        White
                      </b-radio>
                    </div>
                  </b-field>
                </div>
              </div>
            </div>
          </div>
          <hr />
          <div class="has-text-right">
            <!-- <b-button @click="cancel" class="button">Cancel</b-button> -->
            <button
              form="editorForm"
              class="button is-primary ml-4"
              type="submit"
              :disabled="isSubmitting"
            >
              {{ isSubmitting ? "Saving" : "Save" }}
            </button>
          </div>
        </form>
      </div>
    </div>
    <div v-else>
      <Loading />
    </div>
  </div>
</template>

<script>
import { cloneDeep } from "lodash";
import { Chrome } from "vue-color";
import Loading from "@/components/app/Loading";

export default {
  name: "Editor",
  components: {
    "chrome-picker": Chrome,
    Loading
  },
  data() {
    return {
      isSubmitting: false,
      isLoading: false,
      noticeItems: undefined,
      formData: {
        message: undefined,
        enabled: undefined,
        foregroundColour: "#f1f1f1",
        backgroundColour: "#222c4a",
        icon: undefined
      }
    };
  },
  created() {
    this.initialize();
  },
  computed: {
    items() {
      return this.$store.state.noticeObject;
    }
  },
  methods: {
    async initialize() {
      this.isLoading = true;
      await this.$store.dispatch("getNoticeObject");
      this.noticeItems = this.items;
      for (var key in this.noticeItems[0]) {
        if (this.noticeItems[0][key]) {
          this.formData[key] = Array.isArray(this.noticeItems[0][key])
            ? this.noticeItems[0][key].join(", ")
            : this.noticeItems[0][key];
        }
      }
      this.isLoading = false;
    },
    async submitForm() {
      this.isSubmitting = true;
      const request = cloneDeep(this.formData);
      request.backgroundColour = request.backgroundColour.hex;
      await this.$store.dispatch("updateNotice", request);
      this.isSubmitting = false;
      this.initialize();
    }
  }
};
</script>

<style lang="scss">
@import "@/assets/styles/main.scss";
</style>
